@import url("https://fonts.cdnfonts.com/css/campton");

/* CSS Variables */
:root {
  --pry-color: #fff;
  --pry-bold-color: #fff;
  --sec-color: #fff;
  --dark-color: #000;
  --dark-bold-color: #575757;
  --light-color: #fff;
  --light-bold-color: #0000000d;
  --grey-color: #717171;
  --grey-bold-color: #575757;
  --grey-light-color: #999999;
  
}
/* CSS Variables */
